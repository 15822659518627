/* eslint-env browser */
'use strict';

var util = window.util || {

  cookie: {

    accepted: true,

    get: function(name) {
      var cookieName = encodeURIComponent(name) + '=';
      var cookieStart = document.cookie.indexOf(cookieName);
      var cookieValue = null;
      var cookieEnd;

      if (cookieStart > -1) {
        cookieEnd = document.cookie.indexOf(';', cookieStart);
        if (cookieEnd == -1) {
          cookieEnd = document.cookie.length;
        }
        cookieValue = decodeURIComponent(document.cookie.substring(cookieStart + cookieName.length, cookieEnd));
      }

      return cookieValue;
    },

    set: function(name, value, expires, path, domain, secure) {
      if (!this.accepted) {
        return;
      }
      var cookieText = encodeURIComponent(name) + '=' + encodeURIComponent(value);

      if (expires) {
        var date = new Date();
        date.setTime(date.getTime() + (expires * 24 * 60 * 60 * 1000));
        cookieText += '; expires=' + date.toUTCString();
      }

      if (path) {
        cookieText += '; path=' + path;
      }

      if (domain) {
        cookieText += '; domain=' + domain;
      }

      if (secure) {
        cookieText += '; secure';
      }

      document.cookie = cookieText;
    },

    unset: function(name, path, domain, secure) {
      this.set(name, '', new Date(0), path, domain, secure);
    }
  },

  hasScrollBar: function(element) {
    return element.scrollWidth > element.offsetWidth;
  },

  jumpTo: function(target, options) {
    var opt = {
      duration: options.duration || 350,
      offset: options.offset || 0,
      callback: options.callback,
      easing: options.easing || easeInOutQuad
    };
    var start = window.pageYOffset;
    var distance = typeof target === 'string' ? opt.offset + document.querySelector(target).getBoundingClientRect().top : target;
    var duration = typeof opt.duration === 'function' ? opt.duration(distance) : opt.duration;
    var timeStart, timeElapsed;

    function loop(time) {
      timeElapsed = time - timeStart;
      window.scrollTo(0, opt.easing(timeElapsed, start, distance, duration));
      if (timeElapsed < duration) {
        requestAnimationFrame(loop)
      } else {
        end();
      }
    }

    function end() {
      window.scrollTo(0, start + distance);
      if (typeof opt.callback === 'function') {
        opt.callback();
      }
    }

    // Robert Penner's easeInOutQuad - http://robertpenner.com/easing/
    function easeInOutQuad(t, b, c, d)  {
      t /= d / 2
      if(t < 1) return c / 2 * t * t + b
      t--
      return -c / 2 * (t * (t - 2) - 1) + b
    }

    requestAnimationFrame(function(time) {
      timeStart = time;
      loop(time);
    });
  },
};
