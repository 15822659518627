/* eslint-env browser */
;(function() {
  'use strict';

  window.app = window.app || {

    cookie: {
      cookieName: 'airpus.cookieHint',
      expires: 365, // Days
      path: '/',
    },

    itemList: {
      filterChanged: function(select) {
        var option = select.options[select.selectedIndex];
        if (option.hasAttribute('data-url')) {
          window.location.href = option.getAttribute('data-url');
        }
      },
    },

  };

  documentReady(function() {
    var loadedClassName = 'loaded';
    var tmpCarouselNav = false;

    // Cookie Hint
    if (!util.cookie.get(app.cookie.cookieName)) {
      util.cookie.accepted = false;

      var cookieHint = document.getElementById('cookie-hint');
      if (cookieHint) {
        cookieHint.classList.add('is-active');

        document.getElementById('cookie-button').addEventListener('click', function(event) {
          event.preventDefault();
          util.cookie.accepted = true;
          util.cookie.set(app.cookie.cookieName, true, app.cookie.expires, app.cookie.path);
          cookieHint.classList.remove('is-active');
        });
      }
    }

    // Open Menu
    if (document.getElementById('open-menu')) {
      document.getElementById('open-menu').addEventListener('click', function(event) {
        event.preventDefault();
        var html = document.documentElement;
        if (html.classList.contains('is-open-menu')) {
          html.classList.add('open-menu-fadeout');
          setTimeout(function() {
            html.classList.remove('open-menu-fadeout', 'is-open-menu');
          }, 500);
        } else {
          html.classList.add('is-open-menu');
        }
        window.scrollTo(0, 0);
        this.blur();
      });
    }

    /** * disable viewport zooming (iOS 10+ ignore  user-scalable=no)  * **/

   document.addEventListener('touchmove', function(event) {
       event = event.originalevent || event;
       if(event.scale > 1) {
         event.preventDefault();
       }
      }, false);

    // disable double tapping
    var lastTouchEnd = 0;
    document.addEventListener('touchend', function(event) {
      var now = (new Date()).getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
        }
      lastTouchEnd = now;
    }, false);


     /**
      * ********** ********/
    /**
     * Carousel
     * @param {Function} i Index
     */
    function updateNav(i) {
      if (!this.handle) {
        return;
      }

      var nav = this.handle.querySelector('.carousel-nav') || false;
      var bullets = nav && nav.querySelectorAll('li');
      var next = this.handle.querySelector('.next');
      var prev = this.handle.querySelector('.prev');

      if (this.numSlides > 1) {
        if (nav) {
          nav.classList.remove('hidden');
        }
        next.classList.remove('hidden');
        prev.classList.remove('hidden');
      }

      if (!this.options.infinite) {
        if (prev) {
          prev.classList.toggle('disabled', i === 0);
        }
        if (next) {
          next.classList.toggle('disabled', i + 1 >= this.numSlides);
        }
      }

      if (bullets && bullets.length > 0) {
        Array.prototype.forEach.call(bullets, function(bullet) {
          bullet.classList.remove('is-active');
        });
        bullets[i].classList.add('is-active');
      }

      // Trigger scroll event for lazyLoading
      window.scrollTo(window.scrollX, window.scrollY - 1);
      window.scrollTo(window.scrollX, window.scrollY + 1);

      if (nav) {
        syncCarouselNav(nav);
      }
    }

    /**
     * syncCarouselNav
     * @param {Object} nav HTMLElement
     */
    function syncCarouselNav(nav) {
      tmpCarouselNav = nav || false;

      if (!tmpCarouselNav) {
        return;
      }

      var scrollElement = nav.querySelector('.scrollable');
      if (util.hasScrollBar(scrollElement)) {
        scrollElement.scrollLeft = (nav.querySelector('.is-active').offsetLeft - scrollElement.offsetLeft);
      }
    }

    /**
     * onDrag
     * @param {Number} x Drag x position
     * @param {Number} i Index
     */
    function onDrag(x, i) {
      var nextElement = (x >= 0) ? this.slides[i].previousElementSibling : this.slides[i].nextElementSibling;
      var lazyElement = nextElement.querySelector('[data-src]:not(.'+ loadedClassName +')') || false;

      if (lazyElement) {
        if (lazyElement.tagName == 'IMG') {
          if (lazyElement.hasAttribute('src')) {
            return;
          }
          lazyElement.setAttribute('src', lazyElement.getAttribute('data-src'));
        } else {
          if (lazyElement.style.backgroundImage !== '') {
            return;
          }
          lazyElement.style.backgroundImage = 'url(' + lazyElement.getAttribute('data-src') + ')';
        }
        lazyElement.classList.add(loadedClassName);
      }
    }

    var carousels = document.querySelectorAll('.carousel');
    if (carousels) {
      Array.prototype.forEach.call(carousels, function(carouselElement) {
        var bullets = carouselElement.querySelectorAll('.carousel-nav li');
        var countItems = carouselElement.querySelectorAll('.wrap > li').length;
        var display = Math.max(countItems, parseInt(carouselElement.getAttribute('data-carousel-display'))) || 1;
        var carousel = new Carousel(carouselElement, {
          'onDrag': onDrag,
          'onSlide': updateNav,
          'initialIndex': parseInt(carouselElement.getAttribute('data-carousel-index')) || 0,
          'display': display,
          'slideWrap': '.wrap',
          'slides': '.wrap > li',
          'activeClass': 'is-active',
        });

        if (carousel.numSlides == 1) {
          carousel.destroy();
          carouselElement.classList.add('is-disabled');
        }

        if (carousel.numSlides <= display) {
          carouselElement.classList.add('hide-nav');
        }

        carouselElement.querySelector('.prev').addEventListener('click', function(event) {
          event.preventDefault();
          carousel.prev();
        });

				carouselElement.querySelector('.prev').addEventListener('touchend', function(event) {
					event.preventDefault();
					carousel.prev();
				});

        carouselElement.querySelector('.next').addEventListener('click', function(event) {
          event.preventDefault();
          carousel.next();
        });

				carouselElement.querySelector('.next').addEventListener('touchend', function(event) {
					event.preventDefault();
					carousel.next();
				});


        Array.prototype.forEach.call(bullets, function(bullet, index) {
          bullet.addEventListener('click', function(event) {
            event.preventDefault();
            carousel.go(index);
          });
        });

        updateNav.call(carousel, 0);
      });
    }

    /**
     * Expand grid
     */
    if (document.getElementById('expand-button')) {
      var expandButton = document.getElementById('expand-button');

      /**
      * Handle visibility
      */
      function handleExpandButton() {
        if (!document.querySelectorAll('.grid .expand-item.hidden').length) {
          expandButton.parentNode.classList.add('hidden');
        } else {
          expandButton.parentNode.classList.remove('hidden');
        }
      }

      expandButton.addEventListener('click', function(event) {
        event.preventDefault();
        var limit = parseInt(this.getAttribute('data-limit')) - 1;
        var entryItems = document.querySelectorAll('.grid .expand-item.hidden');

        Array.prototype.forEach.call(entryItems, function(projectItem, i) {
          if (i > limit) {
            return;
          }

          projectItem.classList.remove('hidden');
          projectItem.classList.add('is-active');
        });

        handleExpandButton();
     });

     handleExpandButton();
    }

    // Lazyload 8.2.1 https://github.com/verlok/lazyload
    new LazyLoad({
      /* eslint-disable */
      elements_selector: '[data-src]',
      threshold: 1000,
      class_loaded: loadedClassName,
      data_src: 'src',
      data_srcset: 'srcset'
      /* eslint-enable no-alert */
    });

    // Multiple Columns (break-inside)
    var content2columns = document.querySelectorAll('.content-2-columns');
    if (content2columns) {
      Array.prototype.forEach.call(content2columns, function(element) {
        element.setAttribute('data-child-elements', element.querySelector('.text').childElementCount);
      });
    }

    // Sticky elements
    var stickyElements = document.querySelectorAll('.sticky-element');
    if (stickyElements) {



      Array.prototype.forEach.call(stickyElements, function(sticky) {
        sticky.style.display = 'block';
        sticky.style.visibility = 'hidden';

        var stickyHeight = sticky.offsetHeight;
        var stickyOffsetY = Math.round(sticky.offsetTop);

        //console.log(stickyOffsetY);

        var placeholder = document.createElement('DIV');
        placeholder.style.height = stickyHeight + 'px';
        placeholder.style.display = 'none';

        sticky.parentNode.insertBefore(placeholder, sticky.previousSibling);

        sticky.style.display = '';
        sticky.style.visibility = '';

        window.onresize = function() {
          stickyOffsetY = Math.round(sticky.offsetTop);
        };

        window.onscroll = function() {
          var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

          if (scrollTop > stickyOffsetY) {
            sticky.classList.add('pinned');
            placeholder.style.display = 'block';
          } else {
            sticky.classList.remove('pinned');
            placeholder.style.display = 'none';
          }
        };
      });
    }

    window.onresize = function() {
      syncCarouselNav();
    };
  });

  window.onload = function() {
    // Handle external links
    var links = document.querySelectorAll('a[href^="http"]');
    if (links && links.length > 0) {
      Array.prototype.forEach.call(links, function(link) {
        var a = new RegExp('/' + window.location.host + '/');
        if (!a.test(link.href)) {
          link.setAttribute('target', '_blank');
        }
      });
    }

    // Handle anchor links
    var anchorLinks = document.querySelectorAll('a[href*="#"]');
    if (anchorLinks && anchorLinks.length > 0) {
      Array.prototype.forEach.call(anchorLinks, function(link) {
        link.addEventListener('click', function(event) {
          var target = link.getAttribute('href');
          var targetElement = document.getElementById(target.substring(1));
          if (targetElement) {
            util.jumpTo(target, {
              duration: 500,
							offset: -80
            });
          }
        });
      });
    }
            // Handle external links
            $('#contactForm').submit(function(ev) {
              // Prevent the form from actually submitting
              ev.preventDefault();

              // Get the post data
              var data = $(this).serialize();

              // Send it to the server
              $.post('/de/', data, function(response) {
                  if (response.success) {

                      $('#thanks').removeClass('hidden');
                      $('#contactForm').addClass('hidden');
                      $('html,body').animate({scrollTop: $('#thanks').offset().top-200},'slow');
                  } else {
                      // response.error will be an object containing any validation errors that occurred, indexed by field name
                      // e.g. response.error.fromName => ['From Name is required']
                      alert('An error occurred. Please try again.');
                  }
              });
          });

  };

})();
